import { render, staticRenderFns } from "./SpatialActsTable.vue?vue&type=template&id=c3b76b84&scoped=true&"
import script from "./SpatialActsTable.vue?vue&type=script&lang=js&"
export * from "./SpatialActsTable.vue?vue&type=script&lang=js&"
import style0 from "./SpatialActsTable.vue?vue&type=style&index=0&id=c3b76b84&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../vue/vue2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3b76b84",
  null
  
)

export default component.exports