<template>
    <div class="valuation-subject">

        <table class="table table-responsive" style="width: 99%;">
            <thead>
                <tr class="section-title-row">
                    <th :style="{
                        'min-width': '200px',
                    }">
                        {{$t("task_details:valuation")}}
                    </th>
                    <th
                        v-for="(session, idx) in subject.session_list"
                        :key="'session-th-'+idx"
                        :style="{
                            'vertical-align': 'top',
                            'width': 80/subject.session_list.length+'%',
                            'min-width': '300px',
                        }"
                    >
                        <div class="text-center">
                            <div>
                                {{$t("task_details:valuation")}} {{idx+1}}
                                <!--span v-if="!String(session.id).includes('new')">: #{{session.id}}</span-->
                                <a 
                                    v-if="editable"
                                    @click.prevent="removeSession(session)" href="#"
                                    v-tooltip="$t('task_details:valuation:remove')"
                                    class="m-l-5"
                                >
                                    <i class="fa fa-close text-danger"></i>
                                </a>
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        {{$t("general:name")}}
                    </td>
                    <td
                        v-for="(session, idx) in subject.session_list"
                        :key="'session-td-name-'+idx"
                    >
                        <div class="text-center">
                            <base-input-field
                                v-if="editable"
                                v-model="session.name"
                                :placeholder="session.name ? session.name : session.default_name"
                                class="input-wrapper-div center-block"
                                :validator="val => val.length <= 200"
                                :customValidationError="$t('task_details:error:name_too_long:{}').replace('{}', 200)"
                            />
                            <h4 v-else>
                                <a v-if="!String(session.id).includes('new')" :href="getAnalysisSessionUrl(session.id)">
                                    {{getSessionDisplayName(session)}}
                                </a>
                                <div v-else>{{getSessionDisplayName(session)}}</div>
                            </h4>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        {{$t("report:appraisal_date")}} <help-icon slug="predmet-vrednotenja-datum-cenitve" />
                    </td>
                    <td
                        v-for="(session, idx) in subject.session_list"
                        :key="'session-td-appraisal-date-'+idx"
                    >
                        <div class="input-wrapper-div center-block" v-if="editable">
                            <date-select-widget
                                :value="session.appraisal_date"
                                @input="session.appraisal_date = validateAppraisalDate($event)"
                            />
                        </div>
                        <div class="text-center" v-else>
                            {{_tsToDate(session.appraisal_date)}}
                        </div>
                    </td>
                </tr>
            </tbody>

            <!-- Izbrane nepremičnine -->
            <valuation-subject-properties-section
                :subject="subject"
                :editable="editable"
                :size_estimate_indicator="size_estimate_indicator_visibility"
                @open-edit-realestates-modal="openEditRealEstates"
            />

            <!-- Skupne velikosti in tipi -->
            <valuation-subject-sizes-types-section
                :subject="subject"
                :editable="editable"
            />

            <!-- Price zone section -->
            <other-section
                v-if="permissions.priceZonePermission && show_other_section"
                :subject="subject"
                :editable="editable"
            />


            <!-- Obseg dela -->
            <KeepAlive>
            <valuation-subject-edit-scope-section
                v-if="editable"
                :subject="subject"
                :editable="editable"
                :permissions="permissions"
            />
            <valuation-subject-task-status-section
                v-else
                :subject="subject"
                :permissions="permissions"
            />
            </KeepAlive>

            <!-- Recap -->
            <valuation-subject-recap-section
                v-if="!editable"
                :subject="subject"
                :editable="editable"
            />

            <!-- Export -->
            <valuation-subject-export-section
                v-if="!editable"
                :subject="subject"
            />

            <!-- Created by process -->
            <valuation-subject-analysis-metadata-section
                v-if="permissions.createdByProcess && editable"
                :subject="subject"
            />

            <tbody>
            <tr>
              <td></td>
              <td :colspan="subject.session_list.length + 1">

                <div class="text-center">
                    <div
                        v-if="!editable"
                        class="btn btn-info btn-outline"
                        @click="$emit('toggleEditable')"
                    >
                        <span>{{$t("task_details:valuation:edit_subject")}}</span>
                    </div>

                    <div
                        v-if="editable"
                        class="btn btn-info m-r-10"
                        @click="saveSubject"
                    >
                        <span>{{$t("general:save")}}</span>
                    </div>
                    <div
                        v-if="editable"
                        class="btn btn-danger btn-outline"
                        @click="$emit('toggleEditable')"
                    >
                        <span>{{$t("general:cancel:btn")}}</span>
                    </div>

                    <div class="btn btn-info btn-outline pull-right"
                        v-if="editable"
                        @click="addNewSessionToSubject"
                    >
                        {{$t("task_details:valuation:add")}}
                    </div>

                </div>

              </td>

            </tr>
            </tbody>
        </table>

        <bootstrap3-modal
            :title="$t('task_details:valuation:editing_subject')"
            name="editRealEstatesModal"
            width="90%"
        >
            <div v-if="selected_session">
            <valuation-subject-manage-realestates-manual
                v-if="this.getCountryPlugin('AMAS_PROJECT_VALUATION_SCOPE_MANUAL_EDIT')"
                :subject="subject"
                :session="selected_session"
                :loading="false"
                @add-re-to-subject="addRealEstateToSubject"
                @remove-re-from-subject="removeRealEstateFromSubject"
                @get-legal-status="getLegalStatus"
                @set-main-property="setRealEstateMainProperty"
                @close-me="closeEditRealEstates"
                @size-changed="hideSizeEstimateIndicator"
                @set-session-default-name="setSessionDefaultName(selected_session)"
            />
            <valuation-subject-manage-realestates
                v-else
                :subject="subject"
                :session="selected_session"
                :loading="false"
                @add-re-to-subject="addRealEstateToSubject"
                @remove-re-from-subject="removeRealEstateFromSubject"
                @get-legal-status="getLegalStatus"
                @set-main-property="setRealEstateMainProperty"
                @close-me="closeEditRealEstates"
                @size-changed="hideSizeEstimateIndicator"
            />
            </div>
        </bootstrap3-modal>

        <!-- <div class="text-center" v-if="editable">
            <button 
                class="btn btn-info"
                @click="$emit('finish', subject)"
            >
                Shrani
            </button>
        </div> -->
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { Swal } from "@/components/utils/swal.alerts.js";
import ApiCalls from "@/components/utils/api.calls.js";
import Utils from "@/components/utils/utils.js";
import ApiService from "@/components/utils/api.service.js"
import Bootstrap3Modal from "@/components/utils/Bootstrap3Modal.vue";
import BaseInputField from "@/components/utils/inputs/BaseInputField.vue";
import DateSelectWidget from "@/components/widgets/DateSelectWidget.vue";

import VSUtils from "./components/utils.js";

import PropertiesSection from "./parts/PropertiesSection";
import SizesTypesSection from "./parts/SizesTypesSection";
import OtherSection from "./parts/OtherSection";
import EditScopeSection from "./parts/EditScopeSection";
import TaskStatusSection from "./parts/TaskStatusSection";
import RecapSection from "./parts/RecapSection";
import ExportSection from "./parts/ExportSection";
import AnalysisMetadataSection from "./parts/AnalysisMetadataSection";

import ManageRealEstates from "@apps/analysis/project/vue/valuation_subject_wizard/parts/ManageRealEstates.vue";
import ManageRealEstatesManual from "@apps/analysis/project/vue/valuation_subject_wizard/parts/ManageRealEstatesManual.vue";

import HelpIcon from "@/components/widgets/HelpIcon";

import store from "../valuation_subject_wizard/store";
import countrymixin from "@/mixins/countrymixin";

export default {
    mixins: [ countrymixin ],
    store: store,
    model: {
        prop: "valuation_subject",
        event: "change"
    },
    props: {
        valuation_subject: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    components: {
        Loading,
        HelpIcon,
        BaseInputField,
        DateSelectWidget,
        "valuation-subject-properties-section": PropertiesSection,
        "valuation-subject-sizes-types-section": SizesTypesSection,
        "other-section": OtherSection,
        "valuation-subject-edit-scope-section": EditScopeSection,
        "valuation-subject-task-status-section": TaskStatusSection,
        "valuation-subject-recap-section": RecapSection,
        "valuation-subject-export-section": ExportSection,
        "valuation-subject-analysis-metadata-section": AnalysisMetadataSection,
        "valuation-subject-manage-realestates": ManageRealEstates,
        "valuation-subject-manage-realestates-manual": ManageRealEstatesManual,
        "bootstrap3-modal": Bootstrap3Modal,
    },
    data: function () {
        return {
            VSUtils: VSUtils,
            subject: this.valuation_subject,
            loading: false,
            selected_session: null,
            size_estimate_indicator_visibility: true,
            permissions: {
                legalStatus: false,
                administrativeActs: false,
                publicRoadAccess: false,
                comparableAdverts: false,
                comparableTransactions: false,
                costMethod: false,
                esgReport: false,
                incomeBasedApproach: false,
                marketAnalysis: false,
                accessExternalTransactions: false,
                documentPossessionSheet: false,
                documentCadastralPlan: false,

                useManualEditRealEstates: false,
                priceZonePermission: false,
                createdByProcess: false,
            },
        }
    },
    methods: {
        hideSizeEstimateIndicator: function() {
            this.size_estimate_indicator_visibility = false;
        },
        closeEditRealEstates: function() {
            const realEstates = this.subject.real_estates.filter(re => re.session_id === this.selected_session.id);
            if (this.isRequiredDataFilledInForAllRealEstates(realEstates))
                this.$modal.hide("editRealEstatesModal");
        },
        openEditRealEstates: function(session) {
            this.selected_session = session;
            this.$modal.show("editRealEstatesModal");
        },
        isEveryReKeyUnique() {
            for (const session of this.subject.session_list) {
                const sessionRealEstates = this.subject.real_estates.filter(re => re.session_id === session.id);
                const uniqueReKeys = new Set(sessionRealEstates.map(re => re.re_key));
                if (uniqueReKeys.size !== sessionRealEstates.length)
                    return false;
            }
            return true;
            
        },
        isBuildingOnParcelValid() {
            for (const realEstate of this.subject.real_estates) {
                if (realEstate.real_estate && !realEstate.real_estate.is_parcel && realEstate.custom_data && realEstate.custom_data.building_on_parcels) {
                    for (const building of realEstate.custom_data.building_on_parcels) {
                        if (!building.includes('-'))  // must include dash
                            return false;
                    }
                }
            }
            return true;
        },
        isLocationForRealEstateSet(realEstate) {
            return (realEstate.geom && realEstate.geom.geom) || (realEstate.custom_data && realEstate.custom_data.location &&
                realEstate.custom_data.location.lat !== null && realEstate.custom_data.location.lon !== null)
        },
        isRequiredDataFilledIn(realEstate) {
            if (!this.getCountryPlugin('AMAS_PROJECT_VALUATION_SCOPE_MANUAL_EDIT'))
                return true;
            // re_key is required
            if (realEstate.re_key.length === 0)
                return false;
            if (realEstate.custom_data) { // custom object
                // original size or custom size is required
                if (!realEstate.real_estate.size && (realEstate.custom_size === null || realEstate.custom_size.length === 0))
                    return false;
                // location is required
                if (!this.isLocationForRealEstateSet(realEstate))
                    return false;
                // on parcel is required for units
                if (!realEstate.real_estate.is_parcel && realEstate.custom_data.building_on_parcels[0].length === 0)
                    return false;
            }
            // validate size for units
            if (!realEstate.real_estate.is_parcel) {
                if (realEstate.custom_size === null) {
                    return false;
                }
                if (realEstate.custom_size <= 0) {
                    return false;
                }
            }
            return true;
        },
        isRequiredDataFilledInForAllRealEstates(realEstates, detailed=false) {
            for (const realEstate of realEstates) {
                if (!this.isRequiredDataFilledIn(realEstate)) {
                    const description = detailed ? this.$t("task_details:manage:required_fields:detailed") :
                        `${this.$t("general:fill_required_fields:description")}. ${this.$t("task_details:manage:must_set_all_locations")}`;
                    Swal({
                        icon: "warning",
                        title: this.$t("general:fill_required_fields"),
                        text: description,
                        showCancelButton: false,
                        confirmButtonText: this.$t("general:ok"),
                    });
                    return false;
                }
                if (!this.isEveryReKeyUnique()) {
                    Swal({
                        icon: "warning",
                        title: this.$t("task_details:manage:duplicates:description"),
                        showCancelButton: false,
                        confirmButtonText: this.$t("general:ok"),
                    });
                    return false;
                }
                if (!this.isBuildingOnParcelValid()) {
                    Swal({
                        icon: "warning",
                        title: this.$t("task_details:manage:invalid_building_on_parcel"),
                        text: this.$t("task_details:manage:invalid_building_on_parcel:description"),
                        showCancelButton: false,
                        confirmButtonText: this.$t("general:ok"),
                    });
                    return false;
                }
            }
            return true;
        },
        isRealEstateSetForEachSession() {
            for (const session of this.subject.session_list) {
                if (this._getSessionRealEstates(session).length === 0) {
                    return false;
                }
            }
            return true;
        },
        areAnalysesDataValid(analyses) {
            for (const analysis of analyses) {
                if (analysis.name && analysis.name.length && analysis.name.length > 200) {
                    this._alert(this.$t("task_details:error:name_too_long:{}").replace("{}", 200));
                    return false;
                }
            }
            return true;
        },
        _alert: function(text, icon="warning") {
            Swal({   
                title: this.$t("general:warning"),
                text: text,
                icon: icon,
                confirmButtonText: this.$t("general:ok")
            })
        },
        _canAddRealEstateToSubject: function(re_key, session) {
            /*
            Returns true if RealEstate with re_key can be added to subject.
            Currently only check if re_key is not already present in a session.
            */
            if(session && this._getSessionRealEstates(session).map(i => i.re_key).includes(re_key)) {
                this._alert(this.$t("task_details:manage:re_key_is_already_in_appraisal:{}").replace("{}", re_key))
                return false;
            } else {
                return true;
            }
        },
        _getDefaultType(isParcel) {
            return this.$store.state.realEstateTypeMap[isParcel ? "parcel" : "unit"][0].value.toString();
        },
        addRealEstateToSubject: async function(re_key, session_id=null) {
            /*
            Adds RealEstate with re_key to subject if possible. 
            Also retrieves required RealEstate data from backend.
            */
            if(Array.isArray(re_key)) { // "re_keys" is an Array, iterate!
                for (let single_re_key of re_key) { await this.addRealEstateToSubject(single_re_key, session_id); }
                return;
            }
            let session = this._getSessionById(session_id);
            if(this._canAddRealEstateToSubject(re_key, session)) {
                this.loading = true;
                return ApiCalls.getRealEstateData(re_key)
                    .then(real_estate => {
                        this.loading = false;
                        if(real_estate) {
                            this.loading = true;
                            let obj = {
                                "id": "new_" + Math.random().toString(16).slice(2),
                                "re_key": re_key,
                                "custom_type": real_estate.re_type != "None" ? null : this._getDefaultType(real_estate.is_parcel),
                                "custom_size": null,
                                "main_property": false,
                                "real_estate": real_estate,
                                "session_id": null,
                                "geom": null,
                            }
                            this.subject.real_estates.push(obj)
                            this.addRealEstateToSession(obj.id, session_id);
                            // Get RealEstate geometry
                            ApiCalls.getRealEstateGeometry(re_key)
                                .then(res => {
                                    this.loading = false;
                                    this.subject.real_estates
                                        .filter(i => i.re_key===re_key)
                                        .map(i => i.geom = res)
                                });
                        }
                    });
            }
        },
        removeRealEstateFromSubject: function(re_id) {
            /*
            Removes RealEstate with re_id from subject.
            */
            if(this.subject.real_estates.length===1) {
                this._alert(this.$t("task_details:valuation:subject_needs_to_contain_at_least_one_re"))
                return;
            }
            this.subject.real_estates = this.subject.real_estates.filter(i => i.id!=re_id);
            this.removeEmptySessions();
        },
        addNewSessionToSubject: function() {
            let _getSessionId = () => {
                let new_session_ids = this.subject.session_list
                    .filter(session => String(session.id).includes("new_"))
                    .map(session => Number(String(session.id).replace("new_", "")));
                let max_session_id = new_session_ids.length ? Math.max(...new_session_ids) : 0;
                return "new_" + (max_session_id + 1);
            };
            let session = {
                "id": _getSessionId(),
                "default_name": null, // this is set later in "setSessionDefaultName"
                "appraisal_date": this._current_timestamp,

                "custom_main_parcel_type": null,
                "custom_total_parcel_size": null,

                "custom_main_unit_type": null,
                "custom_total_unit_size": null,

                "custom_price_zone": null,

                "scope": this.defaultTasksScope,
            }
            this.subject.session_list.push(session);
            this.setSessionValuesAsync(session);
            return session;
        },
        getSessionDisplayName: function(session) {
            return session.name ? session.name : session.default_name;
        },
        setSessionValuesAsync: function(session) {
            this.setSessionDefaultName(session);
            if(!String(session.id).includes('new')) {
                this.$store.dispatch("retrieveSessionTaskStatus", session.id);
                this.$store.dispatch("retrieveSessionAppraisalValue", session.id);
            }
            return session;
        },
        setSessionDefaultName: function(session) {
            let main_real_estate = VSUtils.getSessionMainRealEstate(this.subject.real_estates, session);
            if(!main_real_estate) {
                session.default_name = this.$t("task_details:valuation:empty");
                return
            }
            let main_type = VSUtils.getRealEstateType(main_real_estate).display;
            const address = main_real_estate.real_estate.address;
            // Some types have special handling/transformation
            let special_name_map = {
                "Stanovanje v enostanovanjski stavbi": "Hiša",
            }
            if (main_type in special_name_map) {
                main_type = special_name_map[main_type]
            }
            let default_name = main_type;
            if (address) {
                default_name += " - " + address;
            } else {
                default_name += " - " + main_real_estate.re_key;
            }
            session.default_name = default_name;
        },
        setSessionMainProperty: function(session) {
            let real_estates_in_session = this._getSessionRealEstates(session);
            if ( real_estates_in_session.length === 0 ) { return ; } // Pass for empty session
            if ( real_estates_in_session.filter(i => i.main_property).length != 1 ) {
                this.setRealEstateMainProperty(real_estates_in_session[0])
            }
        },
        setRealEstateMainProperty: function(real_estate) {
            this.subject.real_estates
                .filter(i => i.session_id==real_estate.session_id)
                .map(i => i.main_property = false);
            real_estate.main_property = true;
            this.setSessionDefaultName(this._getRealEstateSession(real_estate));
        },
        addRealEstateToSession: function(re_id, session_id) {
            let real_estate = this.subject.real_estates.filter(i => String(i.id)===String(re_id))[0];
            let old_session_id = real_estate.session_id;

            if(session_id === null) {
                session_id = this.subject.session_list[0].id;
            } else if(session_id === "new") { // New session
                let session = this.addNewSessionToSubject();
                session_id = session.id;
            }

            let main_property = false;
            if (this.subject.real_estates.filter(i => String(i.session_id)===String(session_id) && i.main_property).length == 0) {
                main_property = true;
            }

            real_estate.session_id = session_id;
            real_estate.main_property = main_property;

            let session = this._getSessionById(session_id);
            this.setSessionDefaultName(session);

            if(old_session_id) {
                let old_session = this._getSessionById(old_session_id);
                this.setSessionMainProperty(old_session);
            }
            this.removeEmptySessions();
        },
        removeSession: function(session) {
            /*
            Removes Session with session_id from subject.
            */
            if(this.subject.session_list.length===1) {
                this._alert(this.$t("task_details:valuation:subject_needs_to_contain_at_least_one_valuation"))
                return;
            }
            this.subject.real_estates = this.subject.real_estates.filter(i => i.session_id!=session.id);
            this.subject.session_list = this.subject.session_list.filter(i => i.id!=session.id);
        },
        removeEmptySessions: function() {
            /*
            Removes Sessions without RealEstates.
            */
            this.subject.session_list.forEach(session => {
                if(this._getSessionRealEstates(session).length===0) {this.removeSession(session);}
            })
        },
        getLegalStatus: function(re_key){
            this.subject.real_estates
                .filter(i => i.re_key===re_key)
                .map(i => i.real_estate.legal_status="loading");
            ApiCalls.getLegalStatus(re_key)
                .then(res => {
                    this.subject.real_estates
                        .filter(i => i.re_key===re_key)
                        .map(i => i.real_estate.legal_status=res);
                })
                .catch(error => {
                    this.subject.real_estates
                        .filter(i => i.re_key===re_key)
                        .map(i => i.real_estate.legal_status=null);
                    this.$notify({
                        group: 'warn',
                        type: 'warn',
                        title: this.$t("general:warning"),
                        text: error.response.data.message,
                    });
                })
        },
        getValuationSubjectEstimation: function(re_key) {
            return ApiCalls.getValuationSubjectEstimation(re_key)
                .then(res => {
                    res.units.forEach(i => this.addRealEstateToSubject(i))
                    res.parcels.forEach(i => this.addRealEstateToSubject(i))
                    return;
                })
        },
        _getRealEstateSession: function(real_estate) {
            return this.subject.session_list.filter(i => i.id===real_estate.session_id)[0];
        },
        _getSessionRealEstates: function(session) {
            return VSUtils.getSessionRealEstates(this.subject.real_estates, session);
        },
        _getSessionById: function(session_id) {
            return this.subject.session_list.filter(i => String(i.id)===String(session_id))[0];
        },

        _tsToDate: function(ts) {
            return Utils.tsToDate(ts);
        },

        validateAppraisalDate: function(val) {
            if(!val || val > this._current_timestamp) {
                return this._current_timestamp;
            }
            return val;
        },
        fetchUserPermissions() {
            ApiService
            .get("/api/v1/current-user")
            .then(res => {
                const permList = res.data.all_permissions_list;
                this.permissions = {
                    legalStatus: permList.includes('analysis.legal'),
                    administrativeActs: permList.includes('analysis.acts_view'),
                    publicRoadAccess: permList.includes('analysis.public_road_access'),
                    comparableTransactions: permList.includes('analysis.transaction_list'),
                    comparableAdverts: permList.includes('analysis.advert_list'),
                    costMethod: permList.includes('cost_method.cost_method_enabled'),
                    esgReport: permList.includes('esg_report.esg_report_enabled'),
                    incomeBasedApproach: permList.includes('analysis.income_calculation'),
                    marketAnalysis: permList.includes('analytics.analytics_dashboard_region'),
                    accessExternalTransactions: permList.includes('userprofile.access_to_external_transactions_db'),
                    documentPossessionSheet: permList.includes('analysis.document_possession_sheet'),
                    documentCadastralPlan: permList.includes('analysis.document_cadastral_plan'),
                    priceZonePermission: permList.includes('analysis.price_zone_permission'),
                    createdByProcess: permList.includes('analysis.can_specify_created_by_process'),
                }
            })
            .catch(error => {
                throw error;
            });
        },
        saveSubject() {
            if (!this.isRealEstateSetForEachSession()) {
                this._alert(this.$t("task_details:valuation:each_subject_needs_to_contain_at_least_one_re"));
                return;
            }
            if (this.isRequiredDataFilledInForAllRealEstates(this.subject.real_estates, true) &&
                this.areAnalysesDataValid(this.subject.session_list)) {
                this.$emit('finish');
            }
        },
    },
    watch: {
        subject: {
            immediate: true,
            deep: true,
            handler(val) {
                this.$emit("change", val);
            }
        },
    },
    computed: {
      show_other_section: function() {
        if(this.subject && this.subject.real_estates){
            for(const obj of this.subject.real_estates){
              if(obj.custom_type == 3 || obj.custom_type == 8){
                return true
              }
            }
        }

        return false
      },
        _current_timestamp: function() {
            return Date.now();
        },
        defaultTasksScope: function() {
            if(!this.isCountryPluginLoaded) { return {}; }
            let scope = this.getCountryPlugin("AMAS_PROJECT_SCOPE");
            let scopeList = {};
            for (let key in scope) {
                if (scope.hasOwnProperty(key)) {
                    scopeList[key] = scope[key].default;
                }
            }
            let specialConditions = {
                comparable_transactions: this.permissions.accessExternalTransactions,
            }
            for (let key in specialConditions) {
                if (specialConditions.hasOwnProperty(key)) {
                    scopeList[key] = scopeList[key] &&specialConditions[key];
                }
            }
            return scopeList;
        },
    },
    mounted() {
        this.fetchUserPermissions();
        this.$store.dispatch("retrieveRealEstateTypeMap");
    }
}
</script>

<style>
.valuation-subject{
  color: #313131;
  overflow-x: auto;
}
.section-title-row {
    background-color: #efefef;
    font-weight: 400;
}
.valuation-subject td{
  padding: 10px 8px !important;
}

.valuation-subject tr.valuation-scope-task td{
  padding: 4px 8px !important;
}
.valuation-subject tr.property-itm td{
  padding: 0px 4px !important;
}

</style>