<template>
    <div class="col-md-12 well">
        <div class="row">
            <div class="col-sm-7 col-lg-8">
                <h2 class="m-b-0 font-medium">
                    {{ value }}
                    <h5 v-if="secondaryValue" class="text-info m-0 m-b-5">{{ secondaryValue }}</h5>
                </h2>
                <h5 class="text-muted m-t-0">{{title}}</h5>
            </div>
            <div class="col-sm-5 col-lg-4">
                <div :id="id" class="pull-right"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Chartist from "chartist";

export default {
    components: {
    },
    props: {
        title: {
            type: String
        },
        value: {
            type: [Number, String],
        },
        secondaryValue: {
            type: [Number, String],
        },
        barValues: {
            type: Array,
            required: true
        }
    },
    data: function () {
        return {
            id: this.title.toLowerCase().replace(/ /g,'').replace(/[^\w-]+/g,'')
        }
    },
    methods: {
        renderBars() {
            Chartist.Bar('#' + this.id, 
                {
                    series: [this.barValues],
                }, 
                {
                    axisX: {
                        showLabel: false,
                        showGrid: false,
                        position: 'start'
                    },
                    chartPadding: {
                        top: -20,
                    },
                    axisY: {
                        showLabel: false,
                        showGrid: false,
                        offset: 0

                    },
                    fullWidth: true,
                    height: 65
                }
            );
        }
    },
    mounted() {
        this.renderBars();
    },
    watch: {
        barValues: function (val) {
            this.renderBars();
        },
    }
}
</script>

<style>
	.ct-series-a .ct-bar{
		stroke: #2cabe3;
	}
</style>