<template>
    <div class="public-road-access-app">
        <loading 
            :active="loading_pra || saving_pra"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div v-if="!pra" class="panel panel-default text-center">
            <div class="panel-heading">
                {{ $t("analysis:tabs:public_road_access") }}
            </div>
            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row" v-if="failed">
                        <p>{{ $t("amas:analysis:pra:access_analysis_failed") }}</p>
                        <button type="button" class="btn btn-info btn-outline" v-on:click="backendRecreate()">
                            {{ $t("general:re_collect") }}
                        </button>
                    </div>

                    <div class="row" v-else>
                        <p>{{ $t("amas:analysis:pra:getting_access") }}</p>
                        <i class="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="panel panel-default">
            
            <div class="panel-heading">
                {{ $t("analysis:tabs:public_road_access") }}

                <div class="pull-right">

                    <button type="button" class="btn btn-success btn-sm btn-outline m-r-5"
                        v-tooltip="$t('general:save')"
                        v-on:click.prevent="savePra()"
                    >
                        {{ $t("general:save") }}
                    </button>

                    <button type="button" class="btn btn-info btn-sm btn-outline"
                        v-on:click="backendRecreate()"
                    >
                        {{ $t("amas:analysis:pra:automatically_update") }}
                    </button>
                    
                </div>
            </div>

            <div class="panel-wrapper collapse in">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="has-access-selector-container">
                                {{ $t("amas:analysis:pra:legal_access") }}
                                <true-false-selector
                                    v-if="pra.result"
                                    v-model="pra.result.has_access"
                                />
                            </div>

                            <div class="has-access-selector-container">
                                {{ $t("amas:analysis:pra:physical_access") }}
                                <true-false-selector
                                    v-if="pra.result"
                                    v-model="pra.result.has_physical_access"
                                />
                            </div>
                        
                            <textarea 
                                v-if="pra.result"
                                v-model="pra.result.comment" placeholder="Komentar"
                                class="form-control" rows="5" style="resize: none;"
                            />

                            <div class="m-t-10">
                                <add-rekeys-textarea
                                    @submit="addStep"
                                    :rows="1"
                                />
                            </div>
                        </div>

                        <div class="col-md-6">
                            <loading 
                                :active="loading_polygons"
                                :can-cancel="false" 
                                :is-full-page="false"
                            />

                            <k-wms-leaflet-map
                                :polygons="allMapPolygons"
                                :center="center"
                                :legend="legend"
                                @polygon-mouseover="highlightItem($event)"
                                @polygon-mouseout="unhighlightItem($event)"
                                :fullscreen="true"
                                :zoom="18"
                                :max-zoom="20"
                                :height="250"
                            >

                                <template v-slot:popup_content_polygon="slotProps">

                                    <table v-if="slotProps.polygon.step" class="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th>{{ $t("general:re_key") }}</th>
                                                <th>{{ $t("amas:analysis:pra:public_road") }}</th>
                                                <th>{{ $t("amas:analysis:pra:legal_access") }}</th>
                                                <th>{{ $t("amas:analysis:pra:physical_access") }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <access-table-row
                                                :step="slotProps.polygon.step"
                                                :real_estate="getRealEstate(slotProps.polygon.property_id)"
                                                :public_road="slotProps.polygon.step.has_public_road"
                                                :minimal="true"
                                                @remove_step="removeStep(slotProps.polygon.property_id)"                                        
                                            />
                                        </tbody>
                                    </table>
                                    
                                    <div v-else class="text-center">
                                        <p>{{ $t("amas:analysis:pra:parcel_id") }} '{{slotProps.polygon.property_id}}'</p>

                                        <span v-if="canAdd(slotProps.polygon.property_id)"
                                            v-tooltip="$t('amas:analysis:pra:add_to_access')" 
                                            class="btn btn-xs btn-outline btn-rounded btn-info m-b-10"
                                            @click="addStep(slotProps.polygon.property_id)"
                                        >
                                            {{ $t("general:add") }}
                                        </span>
                                        <span v-else-if="canRemove(slotProps.polygon.property_id)"
                                            v-tooltip="$t('amas:analysis:pra:remove_from_access')" 
                                            class="btn btn-xs btn-outline btn-rounded btn-danger m-b-10"
                                            @click="removeStep(slotProps.polygon.property_id)"
                                        >
                                            {{ $t("general:remove") }}
                                        </span>
                                    </div>
                                    
                                </template>

                            </k-wms-leaflet-map>

                            <div class="m-t-30 hidden">
                                <div v.if="pra.image">
                                    <p>{{ $t("amas:analysis:pra:static_image_of_access") }}</p>
                                    <img style="max-width:100%" class="image" :src="pra.image">
                                </div>
                                <span>
                                    <a class="btn btn-xs btn-outline btn-info m-t-10" :href="update_image_url">
                                        {{ $t("amas:analysis:pra:update_image") }}
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">

                            <table class="table table-responsive table-hover">
                                <thead>
                                    <tr>
                                        <th>{{ $t("general:re_key") }}</th>
                                        <!-- <th>{{ $t("general:real_estate:type") }}</th> -->
                                        <th>{{ $t("amas:analysis:pra:public_road") }}</th>
                                        <th>{{ $t("amas:analysis:pra:legal_access") }}</th>
                                        <th>{{ $t("amas:analysis:pra:physical_access") }}</th>
                                        <th>{{ $t("amas:analysis:pra:owners") }}</th>
                                        <th>{{ $t("amas:analysis:pra:easements") }}</th>
                                    </tr>
                                </thead>

                                <draggable v-model="accessPathParcels" tag="tbody">
                                    <access-table-row
                                        v-for="(step, step_index) in accessPathParcels"
                                        :key="'tr-path-' + step_index"
                                        :step="step"
                                        :real_estate="getRealEstate(step.property_id)"
                                        :highlight="highlighted_property_id == step.property_id"
                                        :public_road="step.has_public_road"
                                        @remove_step="removeStep(step.property_id)"
                                        @update_legal="setLegalStatusData(step.property_id, true)"
                                    />
                                </draggable>
                            </table>

                            <div v-if="saving_pra" class="pull-right m-t-10">
                                <p>
                                    <i class="fa fa-spinner fa-spin"></i>{{ $t("general:saving") }}
                                </p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <notifications 
            group="warn"
        />
    </div>
</template>

<script>
import L from "leaflet";

import draggable from "vuedraggable";

import ApiService from "@/components/utils/api.service.js"
import ApiCalls from "@/components/utils/api.calls.js"
import { Swal } from "@/components/utils/swal.alerts.js";
import ReOpenModal from "@/components/widgets/ReOpenModal.vue";
import KWMSLeafletMap from "@/components/k-leaflet-map/KWMSLeafletMap"

import AddRekeysTextarea from "@apps/analysis/project/vue/valuation_subject_wizard/components/AddRekeysTextarea.vue"

import AccessTableRow from "./components/AccessTableRow.vue"
import TrueFalseSelector from "./components/TrueFalseSelector.vue"

export default {
    props: {
        session_id: {
            type: Number,
            required: true
        }
    },
    components: {
        draggable,
        ReOpenModal,
        AccessTableRow,
        TrueFalseSelector,
        AddRekeysTextarea,
        "k-wms-leaflet-map": KWMSLeafletMap,
    },
    data: function () {
        return {
            pra: null,
            saving_pra: false,
            loading_pra: false,
            failed: false,

            add_re_key: "",            
            highlighted_property_id: null,
            
            real_estate_data: {},
            original_source_parcels: [],
            surrounding_polygons_raw: [],
            loading_polygons: false,

            // API urls
            pra_url: "/amas/analysis/analysis/api/" + this.session_id + "/road-access",
            update_image_url: "/amas/analysis/" + this.session_id + "/document/road-access/recreate-image",

            // Polygon style settings
            polygon_source_parcel_style: {
                color: "#00753b",
                weight: 3,
                fillOpacity: 0.7
            },
            polygon_in_access_parcel_physical_access_style: {
                color: "#245ab3",
                weight: 3,
                fillOpacity: 0.5
            },
            polygon_in_access_parcel_legal_access_style: {
                color: "#8ed0f9",
                weight: 3,
                fillOpacity: 0.5
            },
            polygon_target_parcel_style: {
                color: "#fffdaa",
                weight: 3,
                fillOpacity: 0.5
            },
            polygon_surrounding_style: {
                color: "#ffffff",
                weight: 2,
                fillOpacity: 0
            }
            
        }
    },
    methods: {
        highlightItem: function(property_id) {
            this.highlighted_property_id = property_id;
        },
        unhighlightItem: function(property_id) {
            this.highlighted_property_id = null;
        },
        backendRecreate: function() {
            const app = this;
            Swal({
                title: app.$t("general:are_you_sure"),   
                text: app.$t("amas:analysis:pra:system_will_update_access:alert"),
                showCancelButton: true,   
                confirmButtonColor: "#DD6B55",   
                confirmButtonText: app.$t("amas:analysis:pra:yes_update"),
                cancelButtonText: app.$t("general:no_cancel"),
            }).then(function(val){
                if (val.isConfirmed) {
                    app.getPra(true);
                } else {     
                    Swal({
                        title: app.$t("general:cancelled"),
                        icon: "error",
                        text: "",
                    });
                } 
            });
        },
        getPra: function(force_update=false) {
            const app = this;
            app.loading_pra = true;
            return ApiService
                .get(this.pra_url, { params: { force_update: force_update } })
                .then(response => {
                    app.loading_pra = false;
                    app.pra = response.data;
                    app.original_source_parcels = this.pra.result.source_parcels.map(parcel => parcel.property_id);
                    app.setAllRealEstateData();
                    return response.data;
                })
                .catch(error => {
                    app.loading_pra = false;
                    if(error.response.status===404) {
                        Swal({
                            title: "",
                            text: app.$t("amas:analysis:pra:access_not_yet_obtained:alert"),
                            showCancelButton: true,   
                            confirmButtonColor: "#DD6B55",   
                            confirmButtonText: app.$t("amas:analysis:pra:confirm_obtain"),
                            cancelButtonText: app.$t("general:no_cancel"),
                        }).then(function(val){
                            if (val.isConfirmed) {
                                app.getPra(true);
                            } else {
                                app.failed = true;
                                Swal({
                                    title: app.$t("general:cancelled"),
                                    icon: "error",
                                });
                            } 
                        });
                    } else {
                        app.failed = true;
                        Swal({
                            title: app.$t("general:error"),
                            icon: "error",
                            text: error.response.data.message,
                        });
                        throw error;
                    }
                })
        },
        savePra: function() {
            const app = this;
            app.saving_pra = true;
            return ApiService
                .post(this.pra_url, this.pra)
                .then(response => {
                    app.saving_pra = false;
                    this.pra = response.data;
                })
                .catch(error => {
                    app.saving_pra = false;
                    Swal({
                        title: app.$t("general:error"),
                        icon: "error",
                        text: error.response.data.message,
                    });
                    throw error;
                })
        },
        setAllRealEstateData: function() {
            const app = this;
            app.allPropertyIDs.forEach(function(property_id, index){
                app.setRealEstateData(property_id);
            });
        },
        setRealEstateData: function(property_id) {
            const app = this;
            app.$set(app.real_estate_data, property_id, "loading");
            return ApiCalls
                .getRealEstateData(property_id)
                .then((real_estate) => {
                    if (real_estate) {
                        app.$set(app.real_estate_data, property_id, real_estate);
                        return real_estate;
                    }
                });
        },
        setLegalStatusData: function(property_id, show_error=false){
            const app = this;
            app.$set(app.real_estate_data[property_id], "legal_status", "loading");
            return ApiCalls
                .getLegalStatus(property_id)
                .then(legal_status => {
                    app.$set(app.real_estate_data[property_id], "legal_status", legal_status);
                })
                .catch(error => {
                    app.$set(app.real_estate_data[property_id], "legal_status", "error");
                    if (show_error) {
                        this.$notify({
                            group: 'warn',
                            type: 'warn',
                            title: 'Opozorilo',
                            text: (error.response && error.response.data) ? error.response.data.message : "Napaka pri pridobivanju pravnega stanja",
                        });
                    }
                    throw error;
                })
        },
        getRealEstate: function(property_id) {
            return this.real_estate_data[property_id];
        },
        // Manage steps
        addStep: function(property_id) {
            if( !this.canAdd(property_id) ) { 
                alert("Ni mogoče dodati");
                return;
            }
            const app = this;
            if (this.isInOriginalParcels(property_id)) {
                app.pra.result.source_parcels.push({
                    property_id: property_id,
                    has_public_road: false,
                    has_legal_access: true,
                    has_physical_access: true,
                });
            } else {
                app.pra.result.access_path.push({
                    property_id: property_id,
                    has_public_road: false,
                    has_legal_access: true,
                    has_physical_access: true,
                })
            }
            app.setRealEstateData(property_id)
                .then((real_estate) => {
                    if (real_estate) {
                        app.setLegalStatusData(property_id);
                    } else {
                        app.removeStep(property_id);
                    }
                })
        },
        removeStep: function(property_id) {
            if( !this.canRemove(property_id) ) { 
                alert("Ni mogoče odstraniti");
                return;
            }
            const app = this;
            if (this.isInOriginalParcels(property_id))
                app.pra.result.source_parcels = app.pra.result.source_parcels.filter(parcel => parcel.property_id !== property_id);
            else
                app.pra.result.access_path = app.pra.result.access_path.filter(step => step.property_id !== property_id);
        },
        canAdd: function(property_id) {
            return !this.allPropertyIDs.includes(property_id);
        },
        canRemove: function(property_id) {
            return this.allPropertyIDs.includes(property_id)
        },
        // Geometry related methods
        getSurroundingPolygons: function(coordinates, distance=100) {
            const app = this;
            app.loading_polygons = true;
            ApiService
                .get("/amas/real-estate/api/geosearch/parcels", {
                    params: {
                        geom: { "type": "Point", "coordinates": coordinates },
                        lookup: "ST_DWithin",
                        distance: distance,
                    }
                })
                .then(response => {
                    app.surrounding_polygons_raw = response.data;
                    app.loading_polygons = false;
                })
        },
        getStepByPropertyID: function(property_id) {
            if (this.allParcelsInPra) {
                let items = this.allParcelsInPra.filter(step => step.property_id==property_id)
                if( items ) { return items[0] }
            }
            return null;            
        },
        getPolygonStyle: function(property_id) {
            if (this.sourcePropertyIDs.includes(property_id)) {
                return this.polygon_source_parcel_style;
            }
            if (this.isPublicRoad(property_id) ) {
                return this.polygon_target_parcel_style;
            }
            if (this.accessPathPropertyIDs.includes(property_id)) {
                if(this.hasLegalAccess(property_id)) {
                    return this.polygon_in_access_parcel_legal_access_style;
                } else {
                    return this.polygon_in_access_parcel_physical_access_style;
                }
            }
            return this.polygon_surrounding_style;
        },
        isPublicRoad: function(property_id) {
            return this.allParcelsInPra.filter(p => p.has_public_road == true).map(p => p.property_id).includes(property_id);
        },
        hasLegalAccess: function(property_id) {
            return this.allParcelsInPra.filter(p => p.has_legal_access == true).map(p => p.property_id).includes(property_id);
        },
        hasPhysicalAccess: function(property_id) {
            return this.allParcelsInPra.filter(p => p.has_physical_access == true).map(p => p.property_id).includes(property_id);
        },
        isInOriginalParcels: function(property_id) {
            const app = this;
            return app.original_source_parcels.includes(property_id)
        }
    },
    mounted() {
        const app = this;
        this.getPra()
            .then(_pra => {
                if(app.center) {
                    app.getSurroundingPolygons([app.center[1], app.center[0]], 200)
                }
            });
    },
    computed: {
        // 1. Source Parcels
        sourceParcels () {
            if (this.pra && this.pra.result && this.pra.result.source_parcels) {
                return this.pra.result.source_parcels;
            }
            return [];
        },
        sourcePropertyIDs() { return this.sourceParcels.map(i=>i.property_id); },
        // 2. Access path Parcels
        accessPathParcels: {
            get () {
                if (this.pra && this.pra.result && this.pra.result.source_parcels) {
                    return this.pra.result.access_path;
                }
                return [];
            },
            set(newValue) {
                if (this.pra && this.pra.result && this.pra.result.source_parcels) {
                   this.pra.result.access_path = newValue
                }
            }
        },
        accessPathPropertyIDs() { return this.accessPathParcels.map(i=>i.property_id); },
        // All
        allParcelsInPra() {
            return this.sourceParcels.concat(this.accessPathParcels);
        },
        allPropertyIDs() { return this.allParcelsInPra.map(i=>i.property_id); },
        // Map polygons
        allMapPolygons() {
            const app = this;
            return app.surrounding_polygons_raw.map(polygon => {
                return {
                    ...polygon.geom_swapped,
                    ...{
                        property_id: polygon.property_id,
                        style: app.getPolygonStyle(polygon.property_id),
                        units_list: polygon.units_list,
                        parcels_list: polygon.parcels_list,
                        step: app.getStepByPropertyID(polygon.property_id),
                    }
                }
            })
        },
        // Map center and legend
        center() {
            let center;
            if(this.pra && this.pra.map_center) {
                center = [this.pra.map_center[1], this.pra.map_center[0]]
            }
            return center;
        },
        legend () {
            const app = this;
            var legend = L.control({position: 'bottomright'});
            legend.onAdd = function (leafletmap) {

                var roadAccessLegendDiv = L.DomUtil.create('div', 'leaflet-legend pra-leaflet-legend'),
                colors = [
                    app.polygon_source_parcel_style.color,
                    app.polygon_in_access_parcel_physical_access_style.color,
                    app.polygon_in_access_parcel_legal_access_style.color,
                    app.polygon_target_parcel_style.color,
                ],
                labels = [
                    app.$t("amas:analysis:pra:parcel_in_question"),
                    "Parcela v dostopu - fizično urejena",
                    "Parcela v dostopu - pravno urejena",
                    app.$t("amas:analysis:pra:parcel_with_public_road"),
                ];

                colors.forEach(function(item, index){
                    roadAccessLegendDiv.innerHTML += `
                    <div class="m-b-5">
                        <svg class="leaflet-icon" width="20" height="20">
                            <rect width="20" height="20" style="fill:${item};"/>
                        </svg>
                        <strong style="color:white;"> - ${labels[index]}</strong>
                    </div>
                    `
                })

                // Toggle legend button
                let toggleButton = L.DomUtil.create('button', 'btn btn-xs pull-right')
                toggleButton.innerHTML += app.$t("maps:legend")
                L.DomEvent.addListener(toggleButton, 'click', function () {
                    roadAccessLegendDiv.toggleAttribute('hidden');
                });

                let div = L.DomUtil.create('div', 'm-0');
                div.appendChild(toggleButton);
                div.appendChild(roadAccessLegendDiv);

                return div;
            };

            return legend;

        }
    }
}
</script>

<style>
.pra-leaflet-legend {
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
}
.pra-leaflet-legend .leaflet-icon {
    vertical-align: middle;
}

.public-road-access-app .has-access-selector-container {
    display: flex;
    align-items: center;
}
.public-road-access-app .dropdown-menu {
    padding: 0;
}

.public-road-access-app  .leaflet-popup-content {
    width: 500px!important;
    margin: 0px;
} .public-road-access-app  .leaflet-popup-content table {
    margin: 0px;
} .public-road-access-app  .leaflet-popup-content table th {
    padding: 3px;
    text-align: center;
    font-weight: bold;
} .public-road-access-app  .leaflet-popup-content table td {
    padding: 3px;
    text-align: center;
    vertical-align: middle;
} .public-road-access-app  .leaflet-popup-content select.form-control {
    padding: 3px;
    height: 20px;
    font-size: 10px;
}
</style>