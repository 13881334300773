<template>
    <div>
        <div class="row">

          <template v-if="real_estate.unit && (real_estate.unit.is_appartment || real_estate.unit.is_house)">

              <div class="col-md-6 col-sm-4">
                  <ul class="re-facts">
                      <li>
                          <i class="ti-fullscreen text-info"></i>
                          <span>
                              <h2>{{ $t('general:size')}}</h2>
                              <h4 v-if="analysis_session">{{analysis_session.total_unit_size|formatSize(2)}}</h4>
                              <h4 v-else>{{real_estate.unit.net_unit_size|formatSize(2)}}</h4>
                          </span>
                      </li>
                  </ul>
              </div>

            
              <!-- <div class="col-md-6 col-sm-4" >
                  <ul class="re-facts">
                      <li>
                          <i class="ti-layout text-info"></i>
                          <span>
                              <h2>Število sob</h2>
                              <h4>{{real_estate.unit.total_rooms||'/'}}</h4>
                          </span>
                      </li>
                  </ul>
              </div> -->
              

              <div class="col-md-6 col-sm-4">
                  <ul class="re-facts">
                      <li>
                          <i class="ti-calendar text-info"></i>
                          <span>
                              <h2>{{ $t('general:year:built')}}</h2>
                              <h4 v-if="analysis_session">{{analysis_session.main_property.year_built||'/'}}</h4>
                              <h4 v-else>{{real_estate.unit.building.year_built||'/'}}</h4>
                          </span>
                      </li>
                  </ul>
              </div>

              <template v-if="real_estate.unit.is_appartment">
                  <div class="col-md-6 col-sm-4">
                      <ul class="re-facts">
                          <li><i class="ti-layout-media-left-alt text-info"></i>
                              <span>
                                  <h2>{{ $t('general:apartment')}}</h2>
                                  <h4>{{real_estate.unit.unit_no||'/'}} /{{real_estate.unit.building.total_appartments||'/'}}</h4>
                              </span>
                          </li>
                      </ul>
                  </div>
                  <div class="col-md-6 col-sm-4">
                      <ul class="re-facts">
                          <li><i class="ti-view-list  text-info"></i>
                              <span>
                                  <h2>{{ $t('general:story')}}</h2>
                                  <h4>{{real_estate.unit.main_entrance_floor_no||real_estate.unit.story_no||'/'}}/{{real_estate.unit.building.total_floors||'/'}}</h4>
                              </span>
                          </li>
                      </ul>
                  </div>
              </template>

              <template v-else-if="real_estate.unit.is_house">
                  <div class="col-md-6 col-sm-4">
                      <ul class="re-facts">
                          <li>
                              <i class="ti-home text-info"></i>
                              <span>
                                  <h2>{{ $t('general:house:type')}}</h2>
                                  <h4>{{ real_estate.unit.house_type||'/' }}</h4>
                              </span>
                          </li>
                      </ul>
                  </div>
                  <div class="col-md-6 col-sm-4">
                      <ul class="re-facts">
                          <li>
                              <i class="ti-map-alt text-info"></i>
                              <span>
                                  <h2>{{ $t('map:parcel:size')}}</h2>
                                  <h4 v-if="analysis_session">{{(analysis_session.total_parcel_size || '/')|formatSize(2)}}</h4>
                                  <h4 v-else v-for="parcel in real_estate.unit.building.get_all_parcels" :key="'p'+parcel.re_key">
                                    <template v-if="parcel.size">
                                      {{parcel.size|formatSize(2)}}
                                    </template>
                                    <template v-else>/</template>
                                  </h4>
                              </span>
                          </li>
                      </ul>
                  </div>
              </template>

          </template>

          <template v-else>
              <div class="col-md-6 col-sm-4">
                  <ul class="re-facts">
                      <li>
                          <i class="ti-fullscreen text-info"></i>
                          <span>
                              <h2>{{ $t('general:size')}}</h2>
                              <h4 v-if="analysis_session">{{analysis_session.total_parcel_size|formatSize(2)}}</h4>
                              <h4 v-else>{{real_estate.size|formatSize(real_estate.parcel ? 0 : 2)}}</h4>
                          </span>
                      </li>
                  </ul>
              </div>

              <div class="col-md-6 col-sm-4">
                  <ul class="re-facts">
                      <li>
                          <i class="ti-map-alt text-info"></i>
                          <span>
                              <h2>
                                    {{ $t("general:type") }}
                                      <span v-if="real_estate.parcel">{{ $t('general:parcel2')}}</span>
                                      <span v-else>{{ $t('general:real_estate2')}}</span>
                              </h2>
                              <h4>{{ real_estate.re_type}}</h4>
                          </span>
                      </li>
                  </ul>
              </div>
          </template>

            <div
                v-if="!analysis_session"
                class="col-md-6 col-sm-4"
            >
              <ul class="re-facts">
                  <li>
                      <i class="ti-money text-info"></i>
                      <span>
                          <h2>{{ $t('map:gurs:estimate')}}</h2>
                          <h4>{{real_estate.value_m2|formatCurrencym2(0)}}</h4>
                      </span>
                  </li>
              </ul>
          </div>
        </div>

        <div class="row" v-if="real_estate.unit && (real_estate.unit.is_appartment || real_estate.unit.is_house)">
            <div class="col-md-6 col-sm-4">
                <ul class="re-facts">
                    <li>
                        <i class="ti-layout text-info"></i>
                        <span>
                            <h2>{{ $t('general:room2')}}</h2>
                            <h4>
                                {{ real_estate.unit.list_rooms.join(', ') }}
                            </h4>
                        </span>
                    </li>
                </ul>
            </div>

            <div class="col-md-6 col-sm-4">
                <ul class="re-facts">
                    <li>
                        <i class="ti-shopping-cart text-info"></i>
                        <span>
                            <h2>{{ $t('real_estate:furniture')}}</h2>
                            <h4>
                                {{ real_estate.unit.list_amenities.join(', ') }}
                            </h4>
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row">
            <data-source dataKey="KN" convertKey="real_estate_market" :lastUpdated="true" />
        </div>
    </div>

</template>

<script>
export default {
    props: {
        real_estate: {
            type: Object,
            required: true
        },
        analysis_session: {
            type: Object,
            default: null,
        }
    },
}
</script>
