<template>
    <div class="container-fluid vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="row">
            <FormulateForm
                v-model="formValues"
            >
                <div class="col-sm-4">
                    <FormPanelSection
                        title="Podatki o cenitvi"
                    >
                        <label>
                            <template v-if="formValues.order">
                                Naročilo {{ formValues.order }},
                            </template>
                            cenitev {{ formValues.id }} 
                        </label>
                        <div class="m-b-10" v-if="formValues.task && formValues.task.task_status">
                            <span :class="`badge badge-${getTaskStatusColor(formValues.task)} label`">
                                {{ formValues.task.status_display }}
                            </span>
                        </div>
                        
                        <FormulateInput
                            name="appraisal_date"
                            :label="$t('depository:appraisal_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIconClass="ti-calendar"
                        />
                        <FormulateInput
                            name="on_site_date"
                            :label="$t('depository:on_site_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIconClass="ti-calendar"
                        />
                        <FormulateInput
                            name="report_date"
                            :label="$t('depository:report_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIconClass="ti-calendar"
                        />
                    </FormPanelSection>

                    <FormPanelSection
                        title="AMAS Analiza"
                        v-if="amasAnalysisEnabled && customEnableAmasAnalysis"
                    >
                        <loading
                            :active="amasAnalysisLoading"
                            :can-cancel="false" 
                            :is-full-page="false"
                        />
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <template v-if="formValues && formValues.analysis_session_appraiser">
                                <a
                                    class="btn btn-default"
                                    style="color: #333 !important;"
                                    :href="`/streamline/supplier/open-amas/${formValues.analysis_session_appraiser}`"
                                >
                                    Odpri v AMAS
                                </a>
                                <button class="btn btn-default" @click="retrieveFromAMAS" :disabled="isReadOnly">Prenesi podatke iz AMAS</button>
                            </template>
                            <button v-else class="btn btn-default" @click="appraiseInAMAS">Oceni v AMAS</button>
                        </div>
                    </FormPanelSection>

                    <FormPanelSection
                        title="Lastniki"
                        v-if="!hideSupplierInputs"
                    >
                        <table class="table">
                            <FormulateInput
                                type="group"
                                name="orderitempropertyowner_set"
                                add-label="+ Dodaj lastnika"
                                remove-label="Odstrani"
                                :repeatable="true&&!isReadOnly" 
                            >
                                <tr class="align-tr">
                                    <td>
                                        <FormulateInput
                                            name="owner_title"
                                            type="text"
                                            :label="$t('depository:owner_title')"
                                            :disabled="isReadOnly"
                                            placeholder="Naziv"
                                            prependIconClass="ti-user"
                                            help="Ime in priimek lastnika oziroma naziv lastninske družbe"
                                        />
                                    </td>
                                    <td>
                                        <FormulateInput
                                            name="share"
                                            type="text"
                                            :label="$t('depository:share')"
                                            placeholder="1/2"
                                            :disabled="isReadOnly"
                                            help="Delež lastništva"
                                        />
                                    </td> 
                                </tr>
                                <tr class="align-tr">
                                    <td>
                                        <FormulateInput
                                            name="owner_phone"
                                            type="text"
                                            :label="$t('depository:owner_phone')"
                                            :disabled="isReadOnly"
                                            prependIconClass="fa fa-phone"
                                        />
                                    </td>
                                    <td>
                                        <FormulateInput
                                            name="owner_email"
                                            type="text"
                                            :label="$t('depository:owner_email')"
                                            :disabled="isReadOnly"
                                            prependIconClass="ti-email"
                                        />
                                    </td>
                                </tr>
                                <tr class="display-block">
                                    <td class="display-block">
                                        <FormulateInput
                                            name="owner_address"
                                            type="text"
                                            :label="$t('depository:owner_address')"
                                            :disabled="isReadOnly"
                                            prependIconClass="ti-home"
                                        />
                                    </td>
                                </tr>
                            </FormulateInput>
                        </table>
                    </FormPanelSection>
                </div>

                <div class="col-sm-8">

                    <FormPanelSection
                        title="Predmet cenitve"
                    >
                        <OrderFormRealEstateTable
                            v-if="formValues.orderitemproperty_set"
                            :properties="formValues.orderitemproperty_set"
                            :isReadOnly="isReadOnly || forceRealEstateTableReadOnly"
                        />
                    </FormPanelSection>

                    <FormPanelSection
                        title="Opisni podatki"
                        v-if="!hideSupplierInputs"
                    >
                        <h3 class="box-title">Lokacija</h3>
                        <div class="row">
                            <div class="col-sm-2">
                                <FormulateInput  name="post_office_id" :label="$t('depository:post_office_id')" type="text" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-2">
                                <FormulateInput  name="post_office_name" :label="$t('depository:post_office_name')" type="text" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-4">
                                <FormulateInput  name="address" :label="$t('general:address')" type="text" :disabled="isReadOnly" prependIconClass="ti-home" />
                            </div>
                            <div class="col-sm-4">
                                <FormulateInput  name="country" :label="$t('depository:country')" type="text" :disabled="isReadOnly" prependIconClass="ti-world" />
                            </div>
                        </div>
                        
                        <hr><h3 class="box-title">Površine</h3>
                        <div class="row">
                            <div class="col-sm-6">
                                <FormulateInput name="total_size" :label="$t('depository:total_size')" type="text" :disabled="isReadOnly" appendIcon="m²" />
                            </div>
                            <!-- <div class="col-sm-4">
                                <FormulateInput name="total_size_of_improvements" :label="$t('depository:total_size_of_improvements')" type="text"  :disabled="isReadOnly" />
                            </div> -->
                            <div class="col-sm-6">
                                <FormulateInput name="usable_size" :label="$t('depository:usable_size')" type="text"  :disabled="isReadOnly" appendIcon="m²" />
                            </div>
                        </div>

                        <hr><h3 class="box-title">Opisi</h3>
                        <div class="row">
                            <div class="col-sm-12">
                                <FormulateInput name="short_description" :label="$t('depository:short_description')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="construction_description" :label="$t('depository:construction_description')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="access_description" :label="$t('depository:access_description')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="msov_type" :label="$t('depository:msov_type')" type="select" :options="selectOptions.msov_type" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="occupant_type" :label="$t('depository:occupant_type')" type="select" :options="selectOptions.occupant_type"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="real_estate_right" :label="$t('depository:real_estate_right')" type="select" :options="selectOptions.real_estate_right"   :disabled="isReadOnly" />
                            </div>
                        </div>
                    </FormPanelSection>
                </div>
            </FormulateForm>
        </div>
        
        <div class="row">
            <FormulateForm
                v-model="formValues"
            >
                <div class="col-sm-12">
                    <FormPanelSection
                        title="Ocena vrednosti"
                        v-if="!hideSupplierInputs"
                    >
                        <div class="row">
                                <!-- <div class="col-sm-12" >
                                <FormulateInput name="time_to_sell" :label="$t('depository:time_to_sell')" type="text"  :disabled="isReadOnly" />
                            </div> -->
                            
                            <div class="col-sm-6">
                                <FormulateInput name="market_value_total" :label="$t('depository:market_value_total')" type="text" validation="required" :disabled="isReadOnly" appendIcon="€" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="liquidation_value" :label="$t('depository:liquidation_value')" type="text"  :disabled="isReadOnly" appendIcon="€" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="market_value_constuction_completed" :label="$t('depository:market_value_constuction_completed')" type="text" :disabled="isReadOnly" appendIcon="€" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="is_building_complete" :label="$t('depository:is_building_complete')" type="select" :options="[
                                    { value: '', label: ''},
                                    { value: '1', label: 'Da' },
                                    { value: '0', label: 'Ne' },
                                ]" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6" >
                                <FormulateInput  name="is_adaptation" :label="$t('depository:is_adaptation')" type="select"  :options="[
                                    { value: '', label: ''},
                                    { value: '1', label: 'Da' },
                                    { value: '0', label: 'Ne' },
                                ]" :disabled="isReadOnly" />
                            </div>

                            <div class="col-sm-12">
                                <FormulateInput name="justification_for_appraisal" :label="$t('depository:justification_for_appraisal')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="notes" :label="$t('depository:notes')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                        </div>
                        
                        <hr><h3 class="box-title">Način tržnih primerjav</h3>
                        <div class="row">
                            <div class="col-sm-6" >
                                <FormulateInput name="appraisal_type_market_rate_approach" :label="$t('depository:appraisal_type_market_rate_approach')" type="text" :disabled="isReadOnly" appendIcon="€" />
                            </div>
                        </div>

                        <hr><h3 class="box-title">Na donosu zasnovan način</h3>
                        <div class="row">
                            <div class="col-sm-6">
                                <FormulateInput name="appraisal_type_income_based_approach" :label="$t('depository:appraisal_type_income_based_approach')" type="text" :disabled="isReadOnly" appendIcon="€" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="capitilisation_value" :label="$t('depository:capitilisation_value')" type="text" :disabled="isReadOnly"/>
                            </div>
                        </div>

                        <hr><h3 class="box-title">Nabavnovrednostni način</h3>
                        <div class="row">
                            <div class="col-sm-6">
                                <FormulateInput name="appraisal_type_purchase_value_approach" :label="$t('depository:appraisal_type_purchase_value_approach')" type="text" :disabled="isReadOnly" appendIcon="€" />
                            </div>
                        </div>
                    </FormPanelSection>
                </div>
            </FormulateForm>
        </div>

        <div class="row">
            <div class="col-sm-12">

                <FormPanelSection
                    title="Dokumenti"
                    v-if="!hideSupplierInputs"
                >
                    <file-upload
                        v-if="!isReadOnly"
                        class="file-upload"
                        :allowDragAndDrop="true"
                        :defaultStyling="true"
                        @file-selected="handleAttachmentUpload"
                        :multiple="true"
                    />

                    <div class="attachments-section">
                        <div
                            v-for="file in uploadingFiles"
                            :key="file.name + (file.id || 'uploading')"
                            class="attachment-item vld-parent"
                        >
                          <loading 
                                :active="file.status === 'deleting'"
                                :can-cancel="false" 
                                :is-full-page="false"
                                :height="30"
                            />
                            <div class="attachment-info">
                                <div class="attachment-main">
                                    <i :class="getAttachmentTypeIcon(file.status)"></i>
    
                                    <div class="attachment-details">
                                        <template v-if="file.editing">
                                            <select 
                                                v-model="file.attachment_type"
                                                class="form-control form-control-sm attachment-type-select"
                                                @change="updateAttachmentType(file)"
                                            >
                                                <option v-for="type in attachmentTypes" :key="type.value" :value="type.value">
                                                    {{ type.label }}
                                                </option>
                                            </select>
                                        </template>
                                        <template v-else>
                                            <span class="attachment-type">{{ getAttachmentTypeName(file.attachment_type) }}</span>
                                            <a
                                                :href="file.file"
                                                target="_blank"
                                                class="attachment-filename"
                                                :class="{ 'uploading': file.status === 'uploading' }"
                                            >
                                                {{ file.name }}
                                            </a>
                                        </template>
                                    </div>
                                </div>

                                <div
                                    v-if="file.status === 'success' && !isReadOnly"
                                    class="attachment-actions"
                                >
                                    <button 
                                        :class="[{
                                            'text-primary': file.editing
                                        }, 'btn btn-link']"
                                        @click="toggleEditMode(file)"
                                    >
                                        <i class="fa" :class="file.editing ? 'fa-check' : 'fa-pencil'"></i>
                                    </button>
                                    <button 
                                        class="btn btn-link text-danger" 
                                        @click="deleteAttachment(file)"
                                        title="Izbriši datoteko">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormPanelSection>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Komentarji"
                >
                    <template #actions>
                        <a @click.stop="refreshComments" class="custom-cursor-pointer"><i class="fa fa-refresh" v-tooltip="'Ponovno naloži'"></i></a>
                    </template>
                    <p class="text-muted">
                        Pogovor <template v-if="readOnly">s cenilcem</template> <template v-else>z naročnikom</template>.
                        Komentarji so vidni tako naročniku kot cenilcu.
                    </p>
                    <div
                        v-if="formValues.orderitemcomment_set && formValues.orderitemcomment_set.length"
                        class="comment-section"
                    >
                        <div
                            v-for="comment in formValues.orderitemcomment_set"
                            :key="comment.id"
                            class="comment"
                        >
                            <!-- Icon -->
                            <span :class="['comment-icon', getCommentIconClass(comment)]" v-tooltip="getCommentIconTooltip(comment)"></span>

                            <div class="comment-content">
                                <div class="comment-header">
                                    <span
                                        v-if="comment.created_by"
                                        class="created-by-section"
                                    >
                                        {{ comment.created_by.first_name }} {{ comment.created_by.last_name }},
                                    </span>
                                    {{ formatDate(comment.created_at, true) }}
                                </div>

                                <div class="comment-body">{{ comment.comment }}</div>

                                <div class="comment-actions" v-if="(comment.is_correction_request && !comment.is_resolved) || comment.resolved_comment">

                                    <template v-if="!comment.is_resolved">
                                        <textarea
                                            v-model="comment.resolved_comment"
                                            class="form-control"
                                            placeholder="Razreši s komentarjem"
                                        ></textarea>
                                        <div>
                                            <button
                                                class="btn btn-sm btn-success"
                                                @click="resolveComment(comment)"
                                            >
                                                Razreši
                                                <i class="fa fa-check"></i>
                                            </button>
                                        </div>
                                    </template>

                                    <div v-else>
                                        <div class="comment-header">
                                            <span
                                                v-if="comment.resolved_by"
                                                class="created-by-section"
                                            >
                                                {{ comment.resolved_by.first_name }} {{ comment.resolved_by.last_name }},
                                            </span>
                                            {{ formatDate(comment.resolved_at, true) }}
                                        </div>
                                        <div class="comment-body">{{ comment.resolved_comment }}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="!isAcceptedAndClosed">
                        <div :class="[{'m-t-30': formValues.orderitemcomment_set && formValues.orderitemcomment_set.length > 0 }, 'm-b-10']">
                            <textarea
                                v-model="currentComment"
                                class="form-control"
                                placeholder="Dodaj komentar"
                                rows="3"
                            ></textarea>
                            <label v-if="actionsEnabled && isTaskStatusSet && !checkOrderItemStatus(formValues.task.task_status.name, orderItemStatuses.STATUS_IN_PREPARATION)">
                                <input type="checkbox" v-model="currentCommentIsCorrectionRequest">
                                Zahtevaj popravek
                            </label>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-sm btn-info" @click="addComment">
                                Dodaj komentar
                            </button>
                        </div>
                    </template>

                </FormPanelSection>
            </div>
        </div>

        <div class="row" v-if="!isReadOnly">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Akcije"
                >
                    <div class="text-center">
                        <button class="btn btn-info btn-sm" @click="saveDraftOrderItem">
                            Shrani osnutek
                        </button>

                        <button class="btn btn-success btn-sm m-l-10" @click="saveAndSubmitOrderItem">
                            Shrani in oddaj cenitev
                        </button>
                    </div>
                </FormPanelSection>
            </div>
        </div>

        <div class="row" v-if="actionsEnabled && !isAcceptedAndClosed && isTaskStatusSet && !checkOrderItemStatus(formValues.task.task_status.name, orderItemStatuses.STATUS_IN_PREPARATION)">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Akcije"
                >
                    <div class="text-center">
                        <button class="btn btn-sm btn-warning" @click="requestUpdate">
                            Zavrni cenitev
                        </button>
                        <button class="btn btn-sm btn-success m-l-10" @click="acceptOrder">
                            Sprejmi cenitev
                        </button>
                    </div>
                </FormPanelSection>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from "vue";

import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

import optionsmixin from "./mixins/optionsmixin.js";
import orderitemstatusmixin from "./mixins/orderitemstatusmixin.js";

import FileUpload from "@/components/utils/FileUpload";
import OrderFormRealEstateTable from "./order_form_parts/OrderFormRealEstateTable.vue";
import FormPanelSection from "./FormPanelSection.vue";
import { formatDate } from "./utils"

export default {
    components: {
        OrderFormRealEstateTable,
        FormPanelSection,
        FileUpload,
    },
    mixins: [ optionsmixin, orderitemstatusmixin ],
    props : {
        forceRealEstateTableReadOnly: {
            type: Boolean,
            default: false,
        },
        orderItemId: {
            type: [String, Number],
            required: false
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        actionsEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        amasAnalysisEnabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed:{
        isEditMode: function() {
            return this.orderItemId != undefined;
        },
        isTaskStatusSet: function() {
            return this.formValues &&
                this.formValues.task &&
                this.formValues.task.task_status;
        },
        isReadOnly: function(){
            return this.readOnly || (
                this.isTaskStatusSet &&
                this.checkOrderItemStatus(
                    this.formValues.task.task_status.name,
                    this.orderItemStatuses.STATUS_SUBMITTED,
                    this.orderItemStatuses.STATUS_ACCEPTED
                )
            );
        },
        isAcceptedAndClosed: function(){
            return this.isTaskStatusSet &&
                this.checkOrderItemStatus(
                    this.formValues.task.task_status.name,
                    this.orderItemStatuses.STATUS_ACCEPTED
                );
        },
        hideSupplierInputs: function() {
            return this.isTaskStatusSet &&
                this.actionsEnabled &&
                this.checkOrderItemStatus(
                    this.formValues.task.task_status.name,
                    this.orderItemStatuses.STATUS_IN_PREPARATION
                )
        }
    },
    methods: {
        formatDate,
        getOrderItem: function(){
            this.loading = true;
            return ApiService
                .get(`/streamline/api/order-item/${this.orderItemId}/`)
                .then(async response => {
                    this.formValues = this._convertBooleanSelect(response.data);

                    const re_keys = this.formValues.orderitemproperty_set.map(property => property.re_key).filter(re_key => re_key);
                    const responseValidation = await ApiService.post("/amas/real-estate/api/validate-keys/", re_keys);
                    this.customEnableAmasAnalysis = Object.values(responseValidation.data).every(value => value);
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju cenitve",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        submitOrderItemAction: function( actionName, postData, successMessage, errorMessage ){
            this.loading = true;
            return ApiService
                .post(`/streamline/api/order-item/${this.formValues.id}/${actionName}/`, postData)
                .then(response => {
                        Swal({
                            title: "",
                            text: successMessage,
                            icon: "success",
                        });
                        this.formValues = response.data;
                        this.loading = false;
                        this.$emit("order-item-action-change");
                        return response.data;
                    })
                .catch(error => {
                    Swal({
                        title: errorMessage,
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        checkCommentsAreResolved: async function(swalText, swalButtonText) {
            // if there are any unresolved comments display a confirmation dialog
            if (
                this.formValues.orderitemcomment_set &&
                this.formValues.orderitemcomment_set.length &&
                this.formValues.orderitemcomment_set
                    .filter(comment => comment.is_correction_request)
                    .some(comment => !comment.is_resolved)
            ) {
                const result = await Swal({
                    title: "Obstajajo nerazrešeni komentarji",
                    text: swalText,
                    icon: "warning",
                    confirmButtonText: swalButtonText,
                    cancelButtonText: "Prekliči",
                    showCancelButton: true,
                })
                if (!result.isConfirmed) {
                    return false;
                }
            }
            return true;
        },
        saveAndSubmitOrderItem: async function() {
            if (await this.checkCommentsAreResolved("Ali ste prepričani, da želite cenitev vseeno oddati?", "Oddaj cenitev")) {
                this
                    .updateOrderItem(this.formValues)
                    .then(_orderItem => {
                        this.submitOrderItemAction("submit", {}, "Cenitev oddana", "Napaka pri oddaji cenitve");
                    });
            }
        },
        saveDraftOrderItem: function() {
            this
                .updateOrderItem(this.formValues)
                .then(_orderItem => {
                    this.submitOrderItemAction("save-draft", {}, "Osnutek shranjen", "Napaka pri shranjevanju cenitve")
                        .then(() => {
                            this.$emit("order-item-saved");
                        });
                })
        },
        _convertBooleanSelect: function(data) {
            const booleanSelectMap = {
                null: "",
                [true]: "1",
                [false]: "0",
            };
            data["is_building_complete"] = booleanSelectMap[data["is_building_complete"]];
            data["is_adaptation"] = booleanSelectMap[data["is_adaptation"]];
            return data;
        },
        updateOrderItem: function() {
            this.loading = true;
            return ApiService
                .put(`/streamline/api/order-item/${this.formValues.id}/`, this.formValues)
                .then((response) => {
                    this.formValues = this._convertBooleanSelect(response.data);
                    return this.formValues;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri posodabljanju cenitve",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        acceptOrder: async function(data) {
            if (await this.checkCommentsAreResolved("Ali ste prepričani, da želite cenitev vseeno sprejeti", "Sprejmi cenitev")) {
                this.submitOrderItemAction("accept", {}, "Cenitev sprejeta", "Napaka pri sprejemanju cenitve");
            }
        },
        requestUpdate: function(data) {
            this.submitOrderItemAction("reject", {"notes": this.formValues.notes_correction}, "Cenitev poslana v dopolnitev", "Napaka pri pošiljanju cenitve v dopolnitev");
        },
        appraiseInAMAS: function() {
            this.amasAnalysisLoading = true;
            return ApiService
                .post(`/streamline/api/order-item/${this.formValues.id}/analysis`, {
                    re_keys: this.formValues.orderitemproperty_set.map(property => property.re_key),
                })
                .then(res => {
                    if (res.data) {
                        this.formValues.analysis_session_appraiser = res.data.analysis_session_appraiser;
                    } else {
                        Swal({
                            title: "",
                            text: "Napaka pri ustvarjanju analize",
                            icon: "error",
                        });
                    }
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri ustvarjanju analize v AMAS",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    // NOTE: This is a workaround to prevent the user opening the AMAS analysis page before the analysis is ready
                    // This will need to be reworked when we have a proper way to check if the analysis is ready!
                    setTimeout(() => {
                        Swal({
                            title: "",
                            text: "Analiza uspešno ustvarjena. Do nje lahko dostopate s klikom na gumb 'Odpri v AMAS'.",
                            icon: "success",
                        });
                        this.amasAnalysisLoading = false;
                    }, 10000);
                })
        },
        retrieveFromAMAS: function() {
            this.loading = true;
            ApiService.get(`/streamline/api/order-item/${this.formValues.id}/analysis`)
            .then(res => {
                if (res.data) {
                    this.formValues = {...this.formValues, ...res.data};

                    Swal({
                        title: "",
                        text: "Podatki uspešno pridobljeni",
                        icon: "success",
                    });
                }
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri pridobivanju podatkov iz AMAS",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        handleAttachmentUpload: function(files) {
            if (!files || files.length === 0) return;

            // upload each file
            files.forEach(file => {
                const formData = new FormData();
                formData.append("file", file);

                // add to uploading files list
                const uploadingFile = {
                    name: file.name,
                    status: "uploading",
                    attachment_type: 6,  // Ostalo
                };
                this.uploadingFiles.push(uploadingFile);
                const fileIndex = this.uploadingFiles.indexOf(uploadingFile);

                // Add attachment type to form data
                formData.append("attachment_type", uploadingFile.attachment_type.toString());                

                // Upload the file
                ApiService.post(`/streamline/api/order-item/${this.formValues.id}/attachment/`, formData)
                    .then(response => {
                        // Update the file entry with the response data
                        this.$set(this.uploadingFiles, fileIndex, {
                            ...this.uploadingFiles[fileIndex],
                            ...response.data,
                            status: "success",
                        });

                        // Update the formValues to include the new attachment
                        if (!this.formValues.orderitemattachment_set) {
                            this.formValues.orderitemattachment_set = [];
                        }
                        this.formValues.orderitemattachment_set.push(response.data);
                    })
                    .catch(() => {
                        this.uploadingFiles[fileIndex].status = "error";
                        Swal({
                            title: "Napaka pri nalaganju prilog",
                            text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                            icon: "error",
                        })
                    });
            });
        },
        deleteAttachment: function(file) {
            if (!file.id) return;

            Vue.set(file, "status", "deleting");
            ApiService.delete(`/streamline/api/order-item/${this.formValues.id}/attachment/${file.id}/`)
                .then(() => {
                    // Remove from uploadingFiles
                    const index = this.uploadingFiles.findIndex(f => f.id === file.id);
                    if (index !== -1) {
                        this.uploadingFiles.splice(index, 1);
                    }

                    // Remove from orderitemattachment_set
                    const attachmentIndex = this.formValues.orderitemattachment_set.findIndex(f => f.id === file.id);
                    if (attachmentIndex !== -1) {
                        this.formValues.orderitemattachment_set.splice(attachmentIndex, 1);
                    }
                })
                .catch(() => {
                    Swal({
                        title: "Napaka pri brisanju prilog",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                });
        },
        getAttachmentTypeName: function(type) {
            const attachmentType = this.attachmentTypes.find(t => t.value === parseInt(type));
            return attachmentType ? attachmentType.label : "Neznani tip";
        },
        getAttachmentTypeIcon: function(status) {
            if (status === "uploading") {
                return "fa fa-spinner fa-spin";
            } else if (status === "error") {
                return "fa fa-exclamation-triangle text-danger";
            }
            return "ti ti-file"
        },
        getFilenameFromUrl: function(url) {
            // Extract filename from URL, removing any query parameters
            const urlPath = url.split("?")[0];
            const urlParts = urlPath.split("/");
            return decodeURIComponent(urlParts[urlParts.length - 1]);
        },
        initializeUploadingFiles: function() {
            if (this.formValues.orderitemattachment_set) {
                this.uploadingFiles = this.formValues.orderitemattachment_set.map(attachment => ({
                    name: this.getFilenameFromUrl(attachment.file),
                    status: "success",
                    attachment_type: attachment.attachment_type,
                    file: attachment.file,
                    id: attachment.pk
                }));
            }
        },
        toggleEditMode: function(file) {
            // If we're canceling edit mode, revert the type back to original
            if (file.editing) {
                file.attachment_type = file.original_type;
            } else {
                file.original_type = file.attachment_type;
            }
            this.$set(file, 'editing', !file.editing);
        },

        updateAttachmentType: function(file) {
            if (!file.id) return;

            const formData = new FormData();
            formData.append("attachment_type", file.attachment_type.toString());

            ApiService.put(`/streamline/api/order-item/${this.formValues.id}/attachment/${file.id}/`, formData)
                .then(response => {
                    // Update both arrays with new data
                    const updatedFile = {
                        ...file,
                        ...response.data,
                        name: this.getFilenameFromUrl(response.data.file),
                        status: "success",
                        editing: false
                    };

                    // Update uploadingFiles
                    const index = this.uploadingFiles.findIndex(f => f.id === file.id);
                    if (index !== -1) {
                        this.$set(this.uploadingFiles, index, updatedFile);
                    }

                    // Update orderitemattachment_set
                    const attachmentIndex = this.formValues.orderitemattachment_set.findIndex(f => f.id === file.id);
                    if (attachmentIndex !== -1) {
                        this.$set(this.formValues.orderitemattachment_set, attachmentIndex, response.data);
                    }
                })
                .catch(() => {
                    // Revert the type back on error
                    file.attachment_type = file.original_type;
                    file.editing = false;
                });
        },
        // Comments
        addComment: function() {
            if (!this.currentComment) {
                Swal({
                    title: "Komentar je obvezen",
                    text: "Prosimo, vnesite komentar, ki bo viden cenilcu",
                    icon: "warning",
                })
                return;
            }

            this.loading = true;
            ApiService.post(`/streamline/api/order-item/${this.formValues.id}/comment/`, {
                comment: this.currentComment,
                is_correction_request: this.currentCommentIsCorrectionRequest,
            })
            .then(res => {
                // update only comments
                this.$set(this.formValues, "orderitemcomment_set", res.data.orderitemcomment_set);
                this.currentComment = "";
                this.currentCommentIsCorrectionRequest = false;
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri dodajanju komentarja",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        resolveComment: function(comment) {
            this.loading = true;
            ApiService.post(`/streamline/api/order-item-comment/${comment.id}/`, {
                resolved_comment: comment.resolved_comment,
            })
            .then(res => {
                Object.assign(comment, res.data);
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri razreševanju komentarja",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        refreshComments: function() {
            this.loading = true;
            return ApiService
                .get(`/streamline/api/order-item/${this.orderItemId}/`)
                .then(async response => {
                    this.$set(this.formValues, "orderitemcomment_set", response.data.orderitemcomment_set);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        getCommentIconClass: function(comment) {
            if (!comment.is_correction_request) {
                return "fa fa-comment";
            } else {
                return comment.is_resolved ? "fa fa-check resolved" : "fa fa-exclamation unresolved";
            }
        },
        getCommentIconTooltip: function(comment) {
            if (!comment.is_correction_request) {
                return "Komentar";
            } else {
                return comment.is_resolved ? "Komentar razrešen" : "Komentar še ni razrešen";
            }
        },
        getTaskStatusColor: function(task) {
            return task.task_status.name === this.orderItemStatuses.STATUS_IN_PREPARATION ?
                "purple" : task.task_status.color;
        }
    },
    data() {
        return {
            formValues: {},
            currentComment: "",
            currentCommentIsCorrectionRequest: false,
            loading: false,
            amasAnalysisLoading: false,
            uploadingFiles: [],
            attachmentTypes: [
                { value: 1, label: "Poročilo cenitve" },
                { value: 2, label: "Gradbeno dovoljenje" },
                { value: 3, label: "Uporabno dovoljenje" },
                { value: 4, label: "Tloris objekta" },
                { value: 5, label: "ZK izpisek" },
                { value: 6, label: "Ostalo" }
            ],
            customEnableAmasAnalysis: true,
        }
    },
    mounted() {
        this.getOrderItem()
            .then(() => {
                this.initializeUploadingFiles();
            });
    }

}
</script>

<style scoped>
.form-section{
    background-color: #dedede;
    padding:20px 10px;
    margin:15px 0;
}
.checkbox-property {
    height: 45px;
}
.align-tr{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

}
.file-upload {
    margin-bottom: 12px;
}
.attachments-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}
.attachment-item {
    padding: 8px 12px;
    background: #f8f9fa;
    border: 1px solid #eee;
    border-radius: 4px;
    transition: background-color 0.2s;
}
.attachment-item:hover {
    background: #e9ecef;
}
.attachment-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.attachment-link {
    display: flex;
    align-items: center;
    color: #007bff;
    text-decoration: none;
    gap: 10px;
    flex: 1;
}
.attachment-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.attachment-type {
    font-weight: 500;
    color: #333;
}
.attachment-filename {
    color: #007bff;
    text-decoration: none;
    font-size: 0.9em;
    word-break: break-all;
}
.attachment-filename:hover {
    text-decoration: underline;
    color: #0056b3;
}
.attachment-filename.uploading {
    pointer-events: none;
    opacity: 0.7;
}
.attachment-main {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}
.attachment-main i {
    margin-right: 4px;
}
.attachment-actions {
    display: flex;
    align-items: center;
    gap: 8px;
}

.attachment-actions .btn-link {
    padding: 4px 8px;
    color: #dc3545;
}

.attachment-actions .btn-link:hover {
    color: #bd2130;
}

.attachment-type-select {
    width: auto;
    min-width: 200px;
    display: inline-block;
}

.attachment-actions .btn-link {
    padding: 4px 8px;
}

.attachment-actions .text-danger:hover {
    color: #bd2130;
}

.attachment-actions .text-primary:hover {
    color: #0056b3;
}

.comment-section {
    display: grid;
    gap: 20px;
}
.comment {
    display: flex;
    gap: 10px;

    border: 1px solid #dee2e6;
    padding: 15px 15px 15px 10px;
    border-radius: 7px;
}
.comment-content {
    flex: 1;
}
.comment-header {
    margin-bottom: 5px;
}
.comment-header .created-by-section {
    font-weight: bold;
}
.comment-icon {
    width: 19px;
    height: 19px;
    text-align: center;
    line-height: 19px;

    background-color: #2cabe3;
    color: white;
    border-radius: 100%;
}
.comment-icon.resolved {
    background-color: #5cb85c;
}
.comment-icon.unresolved {
    background-color: #f0ad4e;
}
.comment-body {
    white-space: pre-wrap;
}
.comment-actions {
    margin-top: 10px;
    border-top: 1px dashed #dee2e6;
    padding-top: 10px;

    display: flex;
    gap: 5px;
}
.comment-actions button {
    height: 100%;
}
</style>
