<template>
    <form>

        <div class="row">

            <div class="col-md-12">

                <div class="text-left field-label">
                    <p><strong>{{ $t('general:real_estate')}}</strong></p>
                </div>
                <box-select-widget
                    v-model="form_data.unit_type"
                    :options="unit_type_options"
                    :sizeClass="'lg'"
                    @change="handleUnitTypeClick"
                />

                <hr>

                <div class="text-left field-label">
                    <p><strong>{{ $t('general:real_estate:type')}}</strong></p>
                </div>
                <box-select-widget
                    v-model="form_data.unit_subtype"
                    :options="getSubtypeChoices"
                    :sizeClass="'sm'"
                    :multiple="true"
                    @change="handleUnitSubTypeClick"
                />

                <hr>

            </div>

            <div class="col-md-12">
                <div class="text-left field-label">
                    <p><strong>{{ $t('general:price')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <formatted-input-field
                            v-model="form_data.transaction_amount_gross__gte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="€"
                            @change="handleFieldUpdated"
                        />
                    </div>

                    <div class="col-xs-6">
                        <formatted-input-field
                            v-model="form_data.transaction_amount_gross__lte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="€"
                            @change="handleFieldUpdated"
                        />
                    </div>
                </div>

                <hr>

                <div class="text-left field-label">
                    <p><strong> {{ $t('general:size')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <formatted-input-field
                            v-model="form_data.unit_rooms_sum_size__gte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="m²"
                            @change="handleFieldUpdated"
                        />
                    </div>

                    <div class="col-xs-6">
                        <formatted-input-field
                            v-model="form_data.unit_rooms_sum_size__lte"
                            :formatter="numberFormatter"
                            :deformatter="numberDeFormatter"
                            inputFieldsAddon="m²"
                            @change="handleFieldUpdated"
                        />
                    </div>
                </div>

                <hr>

                <div class="text-left field-label">
                    <p><strong>{{ $t('general:year:built')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <formatted-input-field
                            v-model="form_data.building_year_built__gte"
                            @change="handleFieldUpdated"
                            :deformatter="(val) => parseInt(val)"
                        />
                    </div>
                    <div class="col-xs-6">
                        <formatted-input-field
                            v-model="form_data.building_year_built__lte"
                            @change="handleFieldUpdated"
                            :deformatter="(val) => parseInt(val)"
                        />
                    </div>
                </div>

                <hr>

                <div class="text-left field-label">
                    <p><strong>{{ $t('general:duration')}}</strong></p>
                </div>
                <div class="row">
                    <div class="col-xs-6">
                        <date-select-widget
                            v-model="form_data.transaction_date__gte"
                        />
                    </div>

                    <div class="col-xs-6">
                        <date-select-widget
                            v-model="form_data.transaction_date__lte"
                        />
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-md-12">
                        <div class="text-left field-label">
                            <p><strong>{{ $t('general:city')}}</strong></p>
                        </div>
                        
                        <typeahead
                            v-model="location"
                            size="sm"
                            :data="searchResults"
                            @hit="locationSelected"
                            append="@"
                        />
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="col-12 text-center">
                <hr>
                <button class="btn btn-info text-center" @click.prevent>
                    {{ $t('general:search')}}
                </button>
                <div class="m-t-10">
                    <button class="btn btn-xs btn-link text-center text-muted" @click.prevent="$emit('reset')">
                      {{ $t('general:reset_parameters')}}
                    </button>
                </div>
                <hr>
            </div>
        </div>
    </form>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import FormattedInputField from '@/components/utils/FormattedInputField.vue';
import Utils from '@/components/utils/utils.js';
import BoxSelectWidget from '@/components/widgets/BoxSelectWidget.vue';
import DateSelectWidget from '@/components/widgets/DateSelectWidget.vue';

export default {
    name: "ComparableTransactionsSearchForm",
    components: {
        "typeahead": VueBootstrapTypeahead,
        DateSelectWidget,
        FormattedInputField,
        BoxSelectWidget
    },
    props: {
        form_data: Object,
    },
    model: {
        prop: "form_data",
        event: "change"
    },
    data: function () {
        return {
            unit_type_options: [
                { 
                    value: "house",
                    text: this.$t('general:house'),
                    icon: "fa fa-home"
                },
                { 
                    value: "appartment",
                    text: this.$t('general:apartment'),
                    icon: "fa fa-building"
                },
                { 
                    value: "land_building",
                    text: this.$t('general:building_parcel'),
                    icon: "fa fa-map-signs"
                },
                { 
                    value: "business",
                    text: this.$t("general:office_realestate"),
                    icon: "fa fa-cart-plus"
                },
                { 
                    value: "building_other",
                    text: this.$t('general:other:buildings'),
                    icon: "fa fa-database"
                },
                { 
                    value: "land_other",
                    text: this.$t('general:other:parcels'),
                    icon: "fa fa-map"
                },
            ],

            location: "",
            searchResults: [],
        };
    },
    methods: {
        locationSelected(location) {
            const app = this;
            this.geoCoderService.geocode(
                { address: location },
                function (results, status) {
                    if (status == window.google.maps.GeocoderStatus.OK) {
                        app.$emit("locationSelected", {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                        })
                    }
                }
            );
        },
        handleUnitTypeClick(val) {
            this.form_data.unit_subtype = [this.getSubtypeChoices[0].value]; // Chooose first value
            this.form_data.unit_type = val;
            this.adjustSizeForUnitType();
            this.handleFieldUpdated();
        },
        handleUnitSubTypeClick(val) {
            this.form_data.unit_subtype = val;
            this.adjustSizeForUnitType();
            this.handleFieldUpdated();
        },
        handleFieldUpdated() {
            this.$emit("change", this.form_data);
        },
        adjustSizeForUnitType() {
            if (this.form_data.unit_type==2) { // Stanovanje
                if (this.form_data.unit_subtype==0) { // Garsonjera
                    this.form_data.unit_rooms_sum_size__gte = 10;
                    this.form_data.unit_rooms_sum_size__lte = 30;
                } else if (this.form_data.unit_subtype==1) { // 1-sobno
                    this.form_data.unit_rooms_sum_size__gte = 30;
                    this.form_data.unit_rooms_sum_size__lte = 45;
                } else if  (this.form_data.unit_subtype==2) { // 2-sobno
                    this.form_data.unit_rooms_sum_size__gte = 45;
                    this.form_data.unit_rooms_sum_size__lte = 65;
                } else if  (this.form_data.unit_subtype==3) { // 3-sobno
                    this.form_data.unit_rooms_sum_size__gte = 65;
                    this.form_data.unit_rooms_sum_size__lte = 90;
                } else if  (this.form_data.unit_subtype==4) { // 4 in več sobno
                    this.form_data.unit_rooms_sum_size__gte = 90;
                    this.form_data.unit_rooms_sum_size__lte = 10000;
                }
            } else { // Ostalo
                this.form_data.unit_rooms_sum_size__gte = 1;
                this.form_data.unit_rooms_sum_size__lte = 10000;
            }
        },
        autocompletersInit() {
            this.autocompleteService = new window.google.maps.places.AutocompleteService();
            this.geoCoderService = new window.google.maps.Geocoder();
        },
        displaySuggestions(predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                this.searchResults = [];
                return;
            }
            this.searchResults = predictions.map(
                (prediction) => prediction.description.replace(", Slovenija", "").replace(", Slovenia", "")
            );
        },

        numberFormatter(value) {
            return Utils.formatNumber(value, 0);
        },
        numberDeFormatter(value) {
            const deformatted = parseInt(value.replaceAll(".", ""));
            if (isNaN(deformatted)) {
                return "";
            }
            return deformatted;
        },
        setFormData: function(field, value) {
            if(field=="re_class"){
                this.form_data["re_type"] = [];
            }
            this.form_data[field] = value;
        },
    },
    computed: {
        getSubtypeChoices: function () {
            let options;
            if(this.form_data.unit_type == "house") {
                options = [
                    { value: 1, text: this.$t('general:house:type:corner') },
                    { value: 2, text: this.$t('general:house:type:detached') },
                    { value: 3, text: this.$t('general:house:type:intermediate') },
                ];
            } else if (this.form_data.unit_type == "appartment") {
                options = [
                    { value: 0, text: this.$t('general:size:studio') },
                    { value: 1, text: "1-" + this.$t('general:size:room') },
                    { value: 2, text: "2-" + this.$t('general:size:room') },
                    { value: 3, text: "3-" + this.$t('general:size:room') },
                    { value: 4, text: "4 " + this.$t('general:and_more') + " " + this.$t('general:size:room')},
                ];
            } else if (this.form_data.unit_type == "land_building") { // Stavbno zemljišče
                options = [
                    { value: 1, text: this.$t('comparable:building_permit:with') }, // ParcelSale.PARCEL_PLOT_PERMIT
                    { value: 2, text: this.$t('property_iq:maps:sewage2') }, // ParcelSale.PARCEL_PLOT_INFRA
                    { value: 3, text: this.$t('property_iq:maps:sewage2:yes_and_no') }, // ParcelSale.PARCEL_PLOT
                ];
            } else if (this.form_data.unit_type == "business") { // Poslovne nepremičnine
                options = [
                    { value: 5, text: this.$t('comparable:office:room') },
                    { value: 6, text: this.$t('comparable:office:customer') },
                    { value: 7, text: this.$t('comparable:office:medicine') },
                    { value: 8, text: this.$t('comparable:office:shop') },
                    { value: 9, text: this.$t('comparable:office:lokal') },
                ];
            } else if (this.form_data.unit_type == "building_other") { // Druge stavbe
                options = [
                    { value: 4, text: this.$t("general:garage") },
                    { value: 11, text: this.$t('general:industrial_buildings') },
                    { value: 15, text: this.$t('general:other') },
                ];
            } else if (this.form_data.unit_type == "land_other") { // Druga zemljišča
                options = [
                    { value: 7, text: this.$t('general:land:pasture')},
                    { value: 8, text: this.$t('general:land:orchard')},
                    { value: 9, text: this.$t('general:land:forest')},
                    { value: 10, text: this.$t('general:other')},
                ];
            } else {
                throw "Invalid unit type: " + this.form_data.unit_type
            }
            return options;
        },
    },
    watch: {
        location(newValue) {
            if (newValue) {
                this.autocompleteService.getPlacePredictions(
                    {
                        input: this.location,
                        types: ["(cities)"],
                        componentRestrictions: { country: "si" },
                    },
                    this.displaySuggestions
                );
            }
        },
    },
    mounted() {
        this.autocompletersInit();
    },
};
</script>

<style>
.input-group-append {
    display: table-cell;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 2px;
    border: 1px solid rgba(120, 130, 140, .13);
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.input-group-append:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
}
</style>
